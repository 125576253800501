import { PAGE_TYPE_ENUM } from "@shared/types";
import { DatoComponentsWrapper } from "components/common/DatoComonentsWrappeer/DatoComponentsWrapper";
import PageLayout from "components/layout/PageLayout";
import { setDYContext } from "config";
import { useLogContext } from "context/log";
import { generatePageId } from "helpers/analyticsLogger";
import { setCachingHeader } from "helpers/cacheHeader";
import { GetServerSideProps } from "next";
import { FC, useEffect } from "react";
import { createDatoClient } from "services/datocms";
import { HomePageQuery, getSdk } from "services/datocms/generated";
import { createQueryParamsForCaching } from "utils/create-query-params";

interface HomePagePageProps {
  homePage: HomePageQuery["homePage"];
}

export const getServerSideProps: GetServerSideProps<HomePagePageProps> = async (
  context,
) => {
  setCachingHeader(context);

  try {
    const { homePage } = await getSdk(
      createDatoClient(
        context.preview,
        createQueryParamsForCaching({ queryType: "HomePage" }),
      ),
    ).HomePage();

    return {
      props: {
        homePage,
      },
    };
  } catch (error) {
    return {
      notFound: true,
    };
  }
};

const Index: FC<HomePagePageProps> = ({ homePage }) => {
  const { useLogPageView } = useLogContext();

  useLogPageView({
    pageType: PAGE_TYPE_ENUM.home,
    id: generatePageId("home"),
  });

  useEffect(() => {
    setDYContext("HOMEPAGE");
  }, []);

  return (
    <PageLayout seo={homePage?.seo} navigationOverlapsContent>
      <DatoComponentsWrapper components={homePage?.components} />
    </PageLayout>
  );
};

export default Index;
